.image-position {
  background-position: 50% 50%;
}

.menu-item {
  min-width: 130px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu-item a {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.menu-item .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: none;
  z-index: 10;
}

.menu-item:hover .dropdown-content,
.menu-item.open .dropdown-content {
  display: flex;
}

.menu-item button svg {
  transition: transform 0.3s ease;
}

.menu-item.open button svg {
  transform: rotate(180deg);
}

.opaque-bg {
  background-color: rgba(23, 23, 23, 0.7);
}

.menu-open {
  max-height: 500px;
  transition: max-height 0.3s ease;
}

/* Submenu */
.dropdown-content {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: rgba(23, 23, 23, 0.952);
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  z-index: 10;
  left: 0;
}

.group:hover .dropdown-content,
.group.open .dropdown-content {
  display: flex;
}

.dropdown-content a {
  padding: 0.5rem 0.5rem;
  display: block;
  color: lightgray;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border-bottom: 1px solid #FFFF00;
  box-shadow: none;
}

.dropdown-content a:hover {
  color: #B6E925;
  background-color: transparent;
  border-bottom: 1px solid #FFFF00;
}

.dropdown-content a:focus {
  outline: none;
  background-color: transparent !important;
  color: #B6E925 !important;
}

@media (max-width: 960px) {
  .dropdown-content {
    position: static;
    top: auto;
    left: auto;
    display: none;
    width: 100%;
  }

  .menu-item.open .dropdown-content {
    display: flex;
  }

  .dropdown-content a:hover {
    color: #B6E925;
    background-color: transparent;
    border-bottom: 1px solid #FFFF00;
  }

  .dropdown-content a:focus {
    outline: none;
    background-color: transparent !important;
    color: #B6E925 !important;
  }

  .hide-responsive {
    display: none;
  }
}

/*Línea izquierda en Collapsible Submenu del Navbar*/
.menu :where(li ul):before{
  width: 0px;
}