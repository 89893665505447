/* .contenedor-logo{ /*vista telefono*//*
  position: absolute;
  width: 100%;
  top: 50px;
  display: flex;
  justify-content: center;
} 
*/
/*
.logo {
  width: 90%;  
  
}
/*
@media (min-width: 768px) { 
  .contenedor-logo{
    position: absolute;
    left: 100px;
    top: 100px;
    width: 600px;
    height: 300px;
    
  }
  
  .logo {
    width: 100%; 
  }
}

.background-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
*/


/* BOTONES BOUNCE */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.scroll-down-btn{
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: theme('colors.verde'); 
  border: none;
  border-radius: 50%; 
  padding: 10px; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex; 
  align-items: center;
  justify-content: center;
  font-size: 24px; 
  width: 50px; 
  height: 50px; 
}

.scroll-down-btn:hover {
  background-color: theme('colors.amarillo');; 
  transform: translateX(-50%) scale(1.05); 
}

.fixed {
  transform: translateX(-50%);
  border-radius: 50%; 
  padding: 10px; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex; 
  align-items: center;
  justify-content: center;
  font-size: 24px; 
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  background-color: theme('colors.amarillo');;
  bottom: 5%; 
}

.fixed:hover {
  background-color: theme('colors.amarillo'); 
  transform: translateX(-50%) scale(1.05); 
}


/* IMAGEN HERO RESPONSIVE */
.bg-responsive {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%; /* Desplaza la imagen hacia la derecha */
  background-position-y: 50%; /* Centra verticalmente */
}

@media (min-width: 768px) { /* breakpoint 'md' en Tailwind */
  .bg-responsive {
    background-size: cover; /* 100% de ancho y altura automática */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

