li {
  display: flex;
  align-items:baseline; /* Alinea los elementos en la línea base */
  margin-bottom: 0.5em; /* Ajusta el espacio entre los elementos de lista */
}

i {
  color: #B6E925; /* Cambia el color */
  font-size: 1.5em; /* Ajusta el tamaño según sea necesario */
  margin-right: 5px; /* Ajusta el espacio entre el icono y el texto */
}

.label {
  flex: 0 0 170px; /* Ajusta este valor según tus necesidades */
  font-weight: bold;
  line-height: 1.5em; /* Ajusta el line-height para que coincida con el tamaño del icono */
}

.value {
  flex: 1;
  padding-left: 0.5em;
  display: flex;
  
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  color: #B6E925;
  font-size: 1.7em;

}


img {
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

img:hover {
  transform: scale(1.1);
  z-index: 2;
}
