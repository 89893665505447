
/* ESTILOS VIDEO YOUTUBE */

.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* Proporción de aspecto 16:9 (9/16 = 0.5625) */
  overflow: hidden;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.material-icons{
  color: black;
}

/* ANIMACIONES TEXTOS DE HOME */
@keyframes slide-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slide-left 0.5s ease-in-out;
}

@keyframes slide-right {
  0% {
    transform: translateX(100%); /* Comienza desde la derecha */
    opacity: 0;
  }
  100% {
    transform: translateX(0); /* Termina en su posición original */
    opacity: 1;
  }
}

/* Aplicando la animación a un elemento */
.animate-slide-right {
  animation: slide-right 0.5s ease-in-out;
}

@keyframes fade-in-from-bottom {
  0% {
    transform: translateY(10px); /* Comienza 10px más abajo */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* Termina en su posición original */
    opacity: 1;
  }
}

/* Aplicando la animación a un párrafo */
p {
  animation: fade-in-from-bottom 1s ease-in-out;
}


.custom-shape-divider-bottom-1697925045 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1697925045 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 36px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1697925045 .shape-fill {
  fill: #171717;
}

/* .header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
} */



.image-container {
  min-height: 100vh; /* Establece una altura mínima del 100% de la altura de la ventana */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  max-height: 100%; /* Establece una altura máxima del 100% de la altura del contenedor */
  width: 100%; /* Permite que el ancho de la imagen se ajuste automáticamente */
}

/*Boton Whatsapp*/

.wsp-button{
  bottom: 14px;
  right: -15px;
  width: 48px;
  height: 48px;
  
}

