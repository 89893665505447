@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  
}

body{
  background-color: #171717;
  overflow-x: hidden;

}


/* .btn {
  font-weight: bold;
} */

