.link:hover {
  font-weight: bold;
  text-decoration-line: none;
}

.footer-title:hover {
  opacity: 100;
  font-weight: bolder;
  text-decoration-line: none;
}

.footer-title2 {
  margin-bottom: 0.5rem/* 8px */;
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0.5;
}






.custom-shape-divider-top-1698008434 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1698008434 svg {
  position: relative;
  display: block;
  width: calc(107% + 1.3px);
  height: 21px;
}

.custom-shape-divider-top-1698008434 .shape-fill {
  fill: #171717;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-top-1698008434 svg {
      width: calc(100% + 1.3px);
      height: 16px;
  }
}


.social-icon i {
  transition: transform 0.3s ease-in-out;
  color: #000; /* Color negro para el ícono de WhatsApp */
  font-size: 26px;
  margin: 0;
  transition: transform 0.3s ease, color 0.3s ease; /* Transición suave */
}

.social-icon:hover i {
  transform: scale(1.2);
}

